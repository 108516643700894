export function getApiBaseUrl(): string {
  if (/localhost:4200/.test(window.location.origin)) {
    return 'http://localhost:26738';
  }

  return 'https://api.' + window.location.hostname;
}

export function getApiConfigUrl(): string {
  return `${getApiBaseUrl()}/config`;
}
